<template>
  <div class="content-container">
    <PageTitle :title="$t('MISSIONS.TITLE')" class="page-title">
      <template #extra-content>
        <div class="red-button"
            @click="preDeleteMission">
          {{ $t('MISSIONS.DELETE') }}
        </div>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('MISSION_DETAILS.DETAILS') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_CMS.MISSIONS" :pre-leavec-heck="preLeaveCheck"/>


    <!-- TODO ADD THE MENU SAYING WHAT STATE THE MISSION IS IN -->
    <Details
      :mission="missionData"
      :edit-mission="editMission"
      :start-opened="false"
    />

    <!-- Slides -->
    <div class="slides-subtitle">
      <h1 class="page-section-title section-title">{{ $t('MISSION_DETAILS.SLIDES') }}</h1>
      <div class="pink-button"
          @click="createSlides">
        {{ $t('MISSION_DETAILS.CREATE_SLIDES') }}
      </div>
    </div>

    <p v-if="!missionData.slides?.length">
      {{ $t('MISSION_DETAILS.NO_SLIDES_YET') }}
    </p>
    <CustomTable
      :options="slideTableOptions"
      :less-padding="false"
      :on-reorder="(id,newIndex) => reorderSlides(id,newIndex)"
      :table-data="missionData.slides">
      <template #slideNumber="props">
        <div>{{ props.rowData.sortIndex }}</div>
      </template>
      <template #title="props">
        <div>{{ props.rowData.title }}</div>
      </template>
      <template #type="props">
        <div class="cell-containing-icon">
          <img :src="getSlideIcon(props.rowData.missionSlideTypeId)"/>
          <div>{{ getSlideName(props.rowData.missionSlideTypeId) }}</div>
        </div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('DELETE')"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        @click="deleteSlideModal(props.rowData)"/>
          <ActionButton :text="$t('EDIT')"
                        :icon="require('../../../assets/icons/icn_edit.svg')"
                        custom-class="button-blue"
                        @click="editSlide(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <basic-modal
      v-if="activeCreateSlide"
      :only-cancel-button="true"
    >
      <template #content="">
        <dia-select-button text="Tekst" :icon="require('../../../assets/icons/icn_aligned-left.svg')" @click="createSlide(0)"/>
        <dia-select-button text="Afbeelding" :icon="require('../../../assets/icons/icn_picture.svg')" @click="createSlide(1)"/>
        <dia-select-button text="Video" :icon="require('../../../assets/icons/icn_video.svg')" @click="createSlide(2)"/>
        <dia-select-button text="Drag & Drop" :icon="require('../../../assets/icons/icn_aligned-left.svg')" @click="createSlide(3)"/>
        <dia-select-button text="Selecteer antwoord" :icon="require('../../../assets/icons/icn_question.svg')" @click="createSlide(4)"/>
        <dia-select-button text="Dilemma" :icon="require('../../../assets/icons/icn_dilemma.svg')" @click="createSlide(5)"/>
      </template>
    </basic-modal>
    <basic-modal v-if="activeDeleteMission || activeDeleteSlide"/>
  </div>
</template>

<script>

import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'

import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, MISSION_SLIDES } from '@/utils/helpers/customTable/CustomTableHelper'
import ActionButton from '@/components/table/ActionButton'
import Details from '@/components/partials/missions/details'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { computed, ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { GET_MISSION_CATEGORIES, GET_MISSION_DETAILS, REORDER_SLIDE, GET_SLIDE_TYPES, RESET_SLIDE_DETAILS } from '@/store/modules/cms/missions/actions.js'
import { useStore } from 'vuex'
import DiaSelectButton from '../../../components/partials/missions/DiaSelectButton.vue'
import { getSlideIcon } from '@/utils/helpers/mission/SlideIcons.js'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import { missionSlideTypes } from '@/utils/data/missionSlideTypes'


export default {
  name: 'MissionDetails',
  components: {
    PageTitle,
    BackButton,
    CustomTable,
    ActionButton,
    Details,
    BasicModal,
    DiaSelectButton,
  },
  setup(props, context) {
    const modalController = ref(new ModalController())
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const missionId = route.params.id
    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const activeDeleteMission = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_MISSION
    )

    const activeCreateSlide = computed(() => {
      return activeModal.value === MODAL_NAMES.CREATE_DIA
    }
    )

    const activeDeleteSlide = computed(() => activeModal.value === MODAL_NAMES.DELETE_SLIDE)

    const slideTypes = missionSlideTypes

    function getSlideName(id) {
      if (!slideTypes.value) {
        return ''
      }
      const found = slideTypes.value.find(value => value.id === id)
      if (found) {
        return found.name
      }
      return ''
    }

    function reorderSlides(id, newIndex) {
      const payload = {
        missionId: missionId,
        id: id,
        slideIndex: newIndex
      }
      store.dispatch(REORDER_SLIDE, payload).then(response => {
        getMissionDetails()
      })
    }


    store.dispatch(GET_MISSION_CATEGORIES)

    const missionData = computed(() => store.getters.getMissionDetails)



    function getMissionDetails() {
      store.dispatch(GET_MISSION_DETAILS, missionId).then(() => {
      })
    }

    getMissionDetails()


    function preDeleteMission() {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.DELETE_MISSION(missionData.value.name),
        () => deleteMission(),
        null,
        null,
        MODAL_NAMES.DELETE_MISSION
      )
    }

    function deleteMission() {
      ApiHandler.delete(`cms/mission/delete-mission/${missionId}`).then(response => {
        modalController.value.resetActiveModal()

        router.push({ name: ROUTE_NAMES_CMS.MISSIONS })
      })
    }

    function editMission() {
      router.push({ name: ROUTE_NAMES_CMS.EDIT_MISSION, params: { id: missionData.value.id } })
    }

    function createSlides() {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.CREATEDIA,
        null,
        null,
        null,
        MODAL_NAMES.CREATE_DIA
      )
    }


    const slideTypeMapping = {
      1: 0,
      2: 1,
      3: 2,
      4: 4,
      5: 4,
      6: 3,
      7: 4,
      8: 4,
      9: 4,
      10: 4,
      11: 4,
      12: 4,
      13: 4,
      14: 5,
      15: 4,
    }

    function editSlide(slide) {
      router.push({ name: ROUTE_NAMES_CMS.EDIT_DIA, params: { id: missionData.value.id, type: slideTypeMapping[slide.missionSlideTypeId], slideId: slide.id } })
    }

    function deleteSlideModal(slide) {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.DELETE_SLIDE(slide.title),
        () => deleteSlide(slide),
        null,
        null,
        MODAL_NAMES.DELETE_MISSION
      )
    }

    function deleteSlide(slide) {
      ApiHandler.delete(`cms/mission/delete-mission-slide/${slide.id}`).then(response => {
        modalController.value.resetActiveModal()
        getMissionDetails()
      })
    }

    function createSlide(slideType) {
      modalController.value.resetActiveModal()
      router.push({ name: ROUTE_NAMES_CMS.CREATE_DIA, params: { type: slideType } })
    }

    function preLeaveCheck() {
      return true
    }

    return {
      missionData,
      reorderSlides,

      ROUTE_NAMES_CMS,
      slideTableOptions: getTableOptions(MISSION_SLIDES),
      preDeleteMission,
      editMission,
      getSlideName,
      createSlides,
      deleteSlideModal,
      deleteSlide,
      editSlide,
      createSlide,
      preLeaveCheck,

      activeModal,
      getSlideIcon,
      activeDeleteMission,
      activeCreateSlide,
      activeDeleteSlide
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.slides-subtitle {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }
}

.cell-containing-icon {
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
}
</style>

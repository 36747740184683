<template>
  <div class="details-wrapper">
    <div class="top-section">
      <div class="top-header">
        <div class="left-text">
          <div class="mission-name"><span class="block-char">■</span>&nbsp;&nbsp;{{ mission.name }}</div>
          <div class="mission-status">-&nbsp;{{ mission.isActivated ? $t('PUBLISHED') : $t('CONCEPT') }}</div>
        </div>
        <ActionButton :text="$t('EDIT')"
                      :icon="require('../../../assets/icons/icn_edit.svg')"
                      custom-class="button-blue"
                      @click="editMission"/>
      </div>
      <p class="mission-description"> {{ mission.description }}</p>
      <div :class="opened ? 'shown' : 'hidden'" class="extra-details">
        <!-- <div class="labelled">
          <p class="label"> {{ $t('CREATE_MISSION.INSTRUCTIONS') }}: </p>
          <ul class="list">
            <li v-for="instruction in mission.instructions"
                :key="instruction"> {{ instruction }}
            </li>
          </ul>
        </div> -->
        <div class="labelled">
          <p class="label"> {{ $t('MISSION_DETAILS.CATEGORY') }}: </p>
          <p class="description"> {{ getCategory(mission.missionCategoryId) }} </p>
        </div>
        <div class="contains-icon">
          <InlineSvg class="icon" :src="require('@/assets/icons/icn_time.svg')"/>
          <p class="description"> {{ mission.durationEstimated + " " + $t('MINUTE') }} </p>
        </div>
        <!-- <div class="contains-icon">
          <InlineSvg class="icon" :src="require('@/assets/icons/icn_calendar.svg')"/>
          <p class="description"> {{ mission.activatedFrom + " - " + mission.activatedUntil }} </p>
        </div> -->
        <div class="contains-icon">
          <InlineSvg class="icon" :src="require('@/assets/icons/icn_tags.svg')"/>
          <p class="description"> {{ displayTags }} </p>
        </div>
        <div class="contains-icon">
          <InlineSvg class="icon"
                     :src="
                       mission.suitableForHighSchool ?
                         require('@/assets/icons/icn_accept.svg') :
                         require('@/assets/icons/icn_reject.svg')"/>
          <p class="description"> {{ $t("CREATE_MISSION.SUITABLE") }} </p>
        </div>


      </div>
      <div class="expand-button"
           @click="expand"
      >
        <p> {{ $t('MISSION_DETAILS.MORE_DETAILS') }} </p>
        <InlineSvg :src="require('@/assets/icons/icn_arrow.svg')" class="expand-arrow" :class="{'flipped' : opened}" />
      </div>
    </div>
  </div>
</template>

<script>

import ActionButton from '../../table/ActionButton.vue'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import InlineSvg from 'vue-inline-svg'


export default {
  name: 'Details',
  components: {
    ActionButton,
    InlineSvg
  },
  props: {
    mission: {
      type: Object,
      required: true
    },
    startOpened: {
      type: Boolean,
      default: true
    },
    editMission: {
      type: Function,
      required: true
    }
  },
  setup(props, context) {
    const store = useStore()
    const opened = ref(props.startOpened)
    function expand() {
      opened.value = !opened.value
    }

    const missionCategories = computed(() => store.getters.getMissionCategories)


    function getCategory(id) {
      const found = missionCategories.value.find(value => value.id === id)
      if (found) {
        return found.name
      }
      return ''
    }

    const displayTags = computed(() => {
      let str = ''
      for (var tag in props.mission.tags) {
        if (str) {
          str += ', '
        }
        str += '#' + props.mission.tags[tag].name
      }
      return str
    })



    return {
      opened,
      expand,
      displayTags,
      getCategory
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.details-wrapper{
  background-color: white;
  padding: rem(25);
  border-radius: rem(10);
  margin-top: rem(10);
  margin-bottom: rem(10);
  height: auto;
  transition: height 0.2s ease-in-out;
  position: relative;
}

.top-section {
  display: flex;
  flex-direction: column;
  gap: rem(10);
  height: fit-content;
  transition: height 0.5s linear;


}

.unpublished-color {
  color: gray
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-text {
    display: flex;
    align-items: center;

    .mission-name, .mission-status {
      font-size: 16px;
    }

    .mission-name {
      font-weight: 700;

      .block-char {
        color: var(--blue_light);
      }
    }

    .mission-status {
      margin-left: rem(10);
    }
  }
}

.mission-description{
    max-width: 80%;
}

.expand-button{
  display: inline-flex;
  gap: rem(10);
  cursor: pointer;
  color: #2760A0;
  font-weight: 1000;
  margin-top: rem(10);
  display: flex;
  align-items: center;
}

.expand-arrow {
  width: rem(8);
  transform: rotate(90deg);

  transition: transform 0.2s ease;

  :deep path {
    fill: var(--blue_link);
  }
}

.flipped {
  transform: rotate(270deg);
}


.hidden{
  max-height: 0;
}

.shown{
  max-height: rem(400);
}

.extra-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  gap: rem(25);
  .labelled{
    display: flex;
    gap: rem(10);
  }

  .contains-icon{
    display: flex;
    gap: rem(20);
  }

  .label{
    font-weight: 600;
  }

  .icon{
    width: rem(20);

    :deep path {
      fill: var(--blue_light);
    }
  }

  .description{

  }

  .list{
    margin: rem(0)
  }

}



</style>
